import Vue from "vue";

// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyAd7n_tl_HbNVdA5ww93V-HCS9AiG2d2pA",
  authDomain: "qipao-legend-hk-ltd.firebaseapp.com",
  databaseURL: "https://qipao-legend-hk-ltd-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "qipao-legend-hk-ltd",
  storageBucket: "qipao-legend-hk-ltd.appspot.com",
  messagingSenderId: "938037555395",
  appId: "1:938037555395:web:de3e793ec5ad773d32b276",
  measurementId: "G-KFCHW3DHB4"
};




Vue.mixin({
  data: function () {
    return {
      get companyName() {
        return "傳旗（香港）有限公司"; //公司名稱
      },
      get regFormDomain() {
        return "https://qipaolegendhkltd.xyz"; 
      },
    };
  },
});

//  溫馨提示：建議使用較淺的顏色
//  歡迎回來的背景顏色
const panelColor        = "#e8f2fa"

//  溫馨提示：建議使用較深的顏色
//  側邊欄的背景顏色
const sideBarColor      = "#000"

//        /||\
//       / || \
//      |------|
//      |  ||  |
//      |  ||  |
//      |  ||  |
//      |  ||  |
//       \ || /
//  不用理 \||/
//        /||\
//       / || \
export {
    firebaseConfig,
    panelColor,
    sideBarColor
}
